import React from "react"

import styled from "styled-components"

import { Stack, Text } from "@kiwicom/orbit-components/lib/"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

import beginCountry from "./images/begin-country.png"
import confirmEntry from "./images/confirm-entry.png"
import editingManualEntry from "./images/editing-manual-entry.jpg"
import manualEntryPrompt from "./images/manual-entry-prompt.jpg"

const SmallImage = styled.img`
  max-width: 400px;
`

const Page = () => (
  <DriveLayout title="Tachograph Guide">
    <Hero title="Using a Tachograph Correctly" />
    <Container size="medium">
      <Stack spacing="XLarge">
        <EmberCard title="Start of Shift Process">
          <EmberCardSection>
            <Stack>
              <Text>
                Follow the steps below at the start of your shift. Make sure you
                don't get distracted when inserting your card and completing
                your manual entry as the tachograph will time-out after 20
                seconds and you will get an infringement. If a passenger asks a
                question half-way, tell them you'll be with them in a second.
              </Text>
              <Text weight="bold">Step 1: Insert Card</Text>
              <Text>
                Put your card into the tachograph as soon as you arrive at the
                bus. It will say "Welcome" and your name as it reads your card,
                then it will pop up a message to confirm the last time your card
                was withdrawn.
              </Text>
              <Text weight="bold">
                Step 2: Confirm You Want to Make a Manual Entry
              </Text>
              <Text>
                After a few seconds, it will ask if you want to make a manual
                entry to confirm what you were doing since you last had your
                card in a Tachograph. The message will say "1M entry addition?"
                as shown below.
              </Text>
              <SmallImage src={manualEntryPrompt} />
              <Text>
                Tap the "OK" button to confirm you do want to make a manual
                entry. This is a legal requirement.
              </Text>
              <Text weight="bold">
                Step 3: Enter the Correct Activity Details
              </Text>
              <Text>
                By default, the tachograph will suggest a single entry that
                starts from the last time you ejected your card and continues
                until the current time. The symbol in the bottom left shows the
                type of activity that is going to be logged for this period
                (e.g. in the example below it would be logging "Other Work").
              </Text>
              <SmallImage src={editingManualEntry} />
              <Text>
                You'll want to edit these details as required. The flashing
                piece of information shows what is currently being edited and
                the up/down arrows allow you to change it. Clicking "OK" moves
                to the next field.
              </Text>
              <Text>
                A common case will be making one manual entry showing you were
                resting since your card was last withdrawn. To do this, use the
                up/down arrows to make sure the rest icon (the bed icon) is
                selected in the bottom-left. Then keep clicking "OK" to advance
                through the end time for your manual entry, not making any
                changes. Finally, click "OK" again to go to the next screen.
              </Text>
              <Text>
                Alternatively, you may need to make multiple entries. To do
                this, just adjust the end time for the first activity as
                required. Then click "OK" and it'll take you to a screen for the
                next entry. Keep doing this until you get to the current time.
              </Text>
              <Text weight="bold">Step 4: Confirm the Country</Text>
              <Text>
                Once you've entered all your activities, it'll ask you to
                confirm the beginning country for your shift. Make sure this is
                set to the UK and click "OK".
              </Text>
              <SmallImage src={beginCountry} />
              <Text weight="bold">Step 5: Confirm your Manual Entry</Text>
              <Text>
                The Tachograph will ask you to confirm you have entered all
                details correctly:
              </Text>
              <SmallImage src={confirmEntry} />
              <Text>
                Press "OK" when it's flashing "Yes" if so. Otherwise, press the
                back button to the left to review your entry.
              </Text>
              <Text weight="bold">Step 6: Switch to Other Work</Text>
              <Text>
                The Tachograph is now logging your time. You'll want to switch
                to "Other Work" whilst doing your walkaround check and getting
                ready to drive by tapping the Driver 1 button until the symbol
                on the bottom-left changes to other work (the crossed hammers
                icon).
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Middle of Shift Process">
          <EmberCardSection>
            <Stack>
              <Text>
                When you stop driving before your break, the tachograph will
                automatically change to "Other Work". It will then change again
                to "Rest" once you turn the coach off.
              </Text>
              <Text>
                You'll need to switch it back to "Other Work" whilst putting the
                coach on charge. Press the "Driver 1" button until the icon on
                the bottom-left switches "Other Work" (crossed hammers). Then
                you can change back to "Rest" just before you head off on your
                break.
              </Text>
              <Text>
                Eject your card and take it with you on your break. There is a
                chance after your break you can leave your card inserted with
                the that you may have a vehicle change, or Hub Support or
                Maintenance may need to use the vehicle. You will need to make
                another manual entry when you return from your break and insert
                your card.
              </Text>
              <Text>
                You should also make sure to switch to "Other Work" if your
                break gets interrupted for any reason, for instance to put a
                coach back on charge. If this is going to stop you getting a
                full break before your next trip then call the Driver Number for
                advice.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="End of Shift Process">
          <EmberCardSection>
            <Stack>
              <Text>
                Set the tachograph to "Other Work" before plugging the coach in
                to charge (same as mid-shift) and then to "Rest" once you are
                ready to leave.
              </Text>
              <Text>
                Press and hold the "Driver 1" button until it shows your name on
                the screen to eject your card. It will ask you to confirm the
                end country. Make sure this is set to the UK, then press "OK".
                After a few seconds your card will be ejected.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Page
